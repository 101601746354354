<template>
  <div class="Activity__Component">
    <div class="Entity__Details__PageOptions__Back" @click="back()">
      <i><img src="@/assets/down-arrow.svg" alt="" /></i>
      חזור
    </div>
    <div class="Activity__Component__Tabs">
      <div class="Activity__Component__Tabs__Item" @click="loadQuestion()" :class="{ active: tab === 1 }">
        עריכה
      </div>
      <!-- <div class="Activity__Component__Tabs__Item" @click="loadQuestionItems()" :class="{ active: tab === 2 }">
        שאלות בתרגול
      </div> -->
    </div>
    <div class="Activity__Component__TabsContent">
      <new-activity ref="noteActivity" v-if="showNewNote" type="Note" @onClose="close" @onSave="saveNote" />
      <new-activity ref="taskActivity" v-if="showNewTask" type="Task" show-title="true" @onClose="closeTask"
        @onSave="saveTask" />
      <template v-if="isLoading">
        <div class="loading">
          <b-loading :is-full-page="false" v-model="isLoading" :can-cancel="false" />
        </div>
      </template>
      <div v-if="tab === 1">
        <div class="Activity__Component__TabsContent__Tasks">
          <div v-if="question" class="Activity__Component__TabsContent__Tasks__Content">

            <!-- <div class="item">
              <h1 class="bold-text" style="display: flex">נתוני חכמת ההמונים</h1>
              <div class="question-details">
                <div class="question-detail">
                  <b-icon title="1- הכי קלה, 10-הכי קשה. נתון זה מתבסס על חכמת ההמונים" pack="fa" icon="exclamation"></b-icon>
                  <p>רמת קושי: {{ question.difficultyLevel }} ({{ question.difficultyPercentage }}% הצלחה)</p>
                </div>
                <div class="question-detail">
                  <b-icon title="כמות משיבים כללית לשאלה זו" pack="fa" icon="exclamation"></b-icon>
                  <p>כמות משיבים: {{ question.studentAnswersCount }}</p>
                </div>
                <div class="question-detail">
                  <b-icon title="זמן ממוצע נטו למענה על שאלה זו" pack="fa" icon="exclamation"></b-icon>
                  <p v-if="question.averageQuestionTime">זמן ממוצע למענה: {{ parseTime(question.averageQuestionTime) }}
                  </p>
                </div>
              </div>
              <div class="question-details">
                <div class="question-detail">
                  <b-icon pack="fa" icon="exclamation"></b-icon>
                  <p>אחוז סימוני כוכבית: {{ question.starPercentage }}%</p>
                </div>
                <div class="question-detail">
                  <b-icon pack="fa" icon="exclamation"></b-icon>
                  <p>ציון כוכבית: {{ question.starGrade || 'אין נתונים' }}</p>
                </div>
                <div class="question-detail">
                  <b-icon pack="fa" icon="exclamation"></b-icon>
                  <p>אחוז אפורות: {{ question.grayPercentage }}</p>
                </div>
                <div class="question-detail">
                  <b-icon pack="fa" icon="exclamation"></b-icon>
                  <p>ציון אפורות: {{ question.grayGrade }}</p>
                </div>
              </div>
            </div> -->

            <div class="item">
              <div style="display: flex; gap: 20px;">
                <b-field style="width: 100%;" label="סטטוס:">
                  <FieldInlineSelect :hideHover="true" style="border: 1px solid gray; width: 100%;" :filterable="true"
                    :clearable="true" :options="[{ Value: false, Text: 'לא פעיל' }, { Value: true, Text: 'פעיל' }]"
                    v-model="question.status" />
                </b-field>
                <b-field style="width: 100%;" label="מוסד:">
                  <FieldInlineSelect :hideHover="true" style="border: 1px solid gray; width: 100%;" :filterable="true"
                    :clearable="true" :optionsAsync="getAcademicInstitudes" v-model="question.academicInstitutionId" />
                </b-field>
                <b-field style="width: 100%;" label="מחבר:">
                  <FieldInlineSelect :hideHover="true" style="border: 1px solid gray; width: 100%;" :filterable="true"
                    :clearable="true" :optionsAsync="getAuthors" v-model="question.authorId" />
                </b-field>
                <!-- <div style="width: 100%;">
                  <b-field style="width: 100%;" label="נוצר על ידי:">
                    <div>{{ question.creatorUserName }} ({{ parseDate(question.createdDate) }})</div>
                  </b-field>
                  <a>היסטוריית עריכה</a>
                </div> -->
              </div>
            </div>

            <div class="item">
              <div style="display: flex; gap: 20px;">
                <b-field style="width: 100%;" label="סוג שאלה:">
                  <FieldInlineSelect :hideHover="true" style="border: 1px solid gray; width: 100%;" :filterable="true"
                    :clearable="true"
                    :options="[{ Value: 1, Text: 'Default' }, { Value: 4, Text: 'טקסט' }, { Value: 5, Text: 'YesNo' }]"
                    v-model="question.exerciseQuestionTypeId" />
                </b-field>
                <b-field style="width: 100%;" label="שם שאלה:">
                  <input type="text" style="border: 1px solid gray; width: 100%; padding: 14px;" v-model="question.name"
                    name="" id="">
                </b-field>
                <b-field style="width: 100%;" label="תיאור קצר:">
                  <input type="text" style="border: 1px solid gray; width: 100%; padding: 14px;"
                    v-model="question.questionDescription" name="" id="">
                </b-field>
                <b-field style="width: 100%;" label="קטגוריות:">
                  <!-- <FieldInlineSelect :multiple="true" :hideHover="true" style="border: 1px solid gray; width: 100%;"
                    :filterable="true" :clearable="true" :optionsAsync="getCategories"
                    v-model="question.exerciseCategoryIds" /> -->
                  <v-select dir="rtl" label="Text" v-model="question.exerciseCategoryIds" :filterable="true"
                    :clearable="true" :reduce="item => item.Value" :getOptionLabel="(item) => `${item.Text}`"
                    :options="categories" :multiple="true" :searchable="true" :close-on-select="false"
                    :placeholder="'בחר ערך מהרשימה'"> </v-select>
                </b-field>
              </div>
            </div>

            <div class="item">
              <b-field label="שאלה">
                <ckeditor :editor="editor" v-model="question.question" :config="editorConfig"></ckeditor>
              </b-field>
            </div>

            <div class="item">
              <b-field label="תשובה מפורטת">
                <ckeditor :editor="editor" v-model="question.detailedAnswer" :config="editorConfig"></ckeditor>
              </b-field>
            </div>

            <div class="item">
              <div style="display: flex; align-items: center;">
                <b-field style="width: 50%" label="סוג אפקט בשאלה:">
                  <FieldInlineSelect :hideHover="true" style="border: 1px solid gray; width: 100%;" :filterable="true"
                    :clearable="true" :options="getEffectTypes" v-model="question.effectId" />
                </b-field>
                <b-checkbox class="bold-text" v-model="question.isHideAnswers">הסתרת חלופות כדיפולט?</b-checkbox>
              </div>
            </div>

            <div class="item">
              <b-field label="נושא שאלה לדוחות ניתוח:">
                <FieldInlineSelect :hideHover="true" style="border: 1px solid gray; width: 100%;" :filterable="true"
                  :clearable="true" :optionsAsync="getExerciseSubjects" v-model="question.questionSubjectId" />
              </b-field>
            </div>

            <div class="item">
              <div style="display: flex; align-items: center; margin-bottom: 5px; gap: 5px">
                <span class="bold-text">הגבלת זמן:</span><b-input type="number"
                  v-model="question.timeToRedAlertInSeconds"></b-input>שניות.
                *לאחר זמן מה השעון בתרגול יצבע באדום
              </div>
            </div>

            <div class="item">
              <div style="display: flex; align-items: center;" class="bold-text">
                <b-checkbox class="bold-text" v-model="question.isInExam">האם שאלת אמת</b-checkbox>
              </div>
              <FieldInlineDate v-if="question.isInExam" style="width: 30%;" :type="'month'" label="חודש המועד" v-model="question.examDate" />
            </div>

            <div class="item">
              <div style="display: flex; align-items: center; margin-bottom: 5px; gap: 5px">
                <span class="bold-text">מספרי פסקאות:</span>
                <b-input type="number" v-model="question.paragraphNumber1"></b-input>
                <b-input type="number" v-model="question.paragraphNumber2"></b-input>
                <b-input type="number" v-model="question.paragraphNumber3"></b-input>
                <b-input type="number" v-model="question.paragraphNumber4"></b-input>
                <b-input type="number" v-model="question.paragraphNumber5"></b-input>
              </div>
            </div>

            <div class="item">
              <b-field label="סעיפים נכונים:">
                <div style="display: flex; align-items: center; gap: 10px;">
                  <FieldInlineSelect @onChange="searchLawClause" :placeholder="'בחר חוק מהרשימה'" :hideHover="true"
                    style="border: 1px solid gray; width: 30%;" :filterable="true" :clearable="true"
                    :reduce="(law) => law" :optionsAsync="getLaws" v-model="question.law" />
                  <FieldInlineSelect v-if="question.law" :hideHover="true" style="border: 1px solid gray; width: 30%;"
                    :filterable="true" :clearable="true" :optionsAsync="getLawClauses" v-model="question.clauseId" />
                  <button class="new" @click="addLawClause" v-if="question.clauseId">הוסף</button>
                </div>
                <div v-if="question.correctClauses && question.correctClauses.length" class="clauses">
                  <div style="display: flex; align-items: center; gap: 10px; margin-top: 10px"
                    v-for="clause, idx in question.correctClauses" :key="idx" class="clause">
                    <div>{{ clause.lawName }} סעיף: {{ clause.clauseId }}</div>
                    <button @click="removeLawClause(clause.clauseId)">X הסרה</button>
                  </div>
                </div>
              </b-field>
            </div>

            <!-- <div class="item">
              <b-field label="">
                <b-checkbox class="bold-text" v-model="question.status">האם לאפשר פתרון להדפסה</b-checkbox>
                <b-checkbox class="bold-text" v-model="question.status">האם לאפשר שאלון להדפסה</b-checkbox>
              </b-field>
            </div> -->

            <div v-if="question.answers" v-for="answer, idx in question.answers" :key="idx" class="item">
              <div style="display: flex; align-items: center;" class="bold-text">
                תשובה {{ idx + 1 }}
                <b-checkbox class="bold-text" v-model="answer.isCorrectAnswer">האם נכונה</b-checkbox>
              </div>
              <ckeditor :editor="editor" v-model="answer.answer" :config="editorConfig"></ckeditor>
              <div style="display: flex; gap: 20px;">
                <b-field style="width: 30%;" label="מיומנות:">
                  <FieldInlineSelect :hideHover="true" style="border: 1px solid gray; width: 100%;" :filterable="true"
                    :clearable="true" :optionsAsync="getMeyumanot" v-model="answer.skill" />
                </b-field>
                <b-field style="width: 30%;" label="חומרת שגיאה:">
                  <FieldInlineSelect :hideHover="true" style="border: 1px solid gray; width: 100%;" :filterable="true"
                    :clearable="true" :options="getSeverities" v-model="answer.severity" />
                </b-field>
                <b-field style="width: 60%;" label="הסבר:">
                  <FieldInlineSelect :hideHover="true" style="border: 1px solid gray; width: 100%;" :filterable="true"
                    :clearable="true" :optionsAsync="getExplanations" v-model="answer.explanationId" />
                </b-field>
              </div>
              <div class="bold-text">
                הערה לתשובה
              </div>
              <ckeditor :editor="editor" v-model="answer.remark" :config="editorConfig"></ckeditor>
            </div>

            <!-- <div class="item">
              <div style="display: flex; align-items: center;" class="bold-text">
                תשובה 1
                <b-checkbox class="bold-text" v-model="question.answer1IsCorrectAnswer">האם נכונה</b-checkbox>
              </div>
              <ckeditor :editor="editor" v-model="question.answer1Answer" :config="editorConfig"></ckeditor>
              <div style="display: flex; gap: 20px;">
                <b-field style="width: 30%;" label="מיומנות:">
                  <FieldInlineSelect :hideHover="true" style="border: 1px solid gray; width: 100%;" :filterable="true"
                    :clearable="true" :options="[{ Value: 0, Text: 'לא פעיל' }, { Value: 1, Text: 'פעיל' }]"
                    v-model="question.answer1Skill" />
                </b-field>
                <b-field style="width: 30%;" label="חומרת שגיאה:">
                  <FieldInlineSelect :hideHover="true" style="border: 1px solid gray; width: 100%;" :filterable="true"
                    :clearable="true" :optionsAsync="getAcademicInstitudes" v-model="question.answer1Severity" />
                </b-field>
                <b-field style="width: 60%;" label="הסבר:">
                  <FieldInlineSelect :hideHover="true" style="border: 1px solid gray; width: 100%;" :filterable="true"
                    :clearable="true" :optionsAsync="getAuthors" v-model="question.answer1ExplainationId" />
                </b-field>
              </div>
              <div class="bold-text">
                הערה לתשובה
              </div>
              <ckeditor :editor="editor" v-model="question.answer1Remark" :config="editorConfig"></ckeditor>
            </div>

            <div class="item">
              <div style="display: flex; align-items: center;" class="bold-text">
                תשובה 2
                <b-checkbox class="bold-text" v-model="question.answer2IsCorrectAnswer">האם נכונה</b-checkbox>
              </div>
              <ckeditor :editor="editor" v-model="question.answer2Answer" :config="editorConfig"></ckeditor>
              <div style="display: flex; gap: 20px;">
                <b-field style="width: 30%;" label="מיומנות:">
                  <FieldInlineSelect :hideHover="true" style="border: 1px solid gray; width: 100%;" :filterable="true"
                    :clearable="true" :options="[{ Value: 0, Text: 'לא פעיל' }, { Value: 1, Text: 'פעיל' }]"
                    v-model="question.answer2Skill" />
                </b-field>
                <b-field style="width: 30%;" label="חומרת שגיאה:">
                  <FieldInlineSelect :hideHover="true" style="border: 1px solid gray; width: 100%;" :filterable="true"
                    :clearable="true" :optionsAsync="getAcademicInstitudes" v-model="question.answer2Severity" />
                </b-field>
                <b-field style="width: 60%;" label="הסבר:">
                  <FieldInlineSelect :hideHover="true" style="border: 1px solid gray; width: 100%;" :filterable="true"
                    :clearable="true" :optionsAsync="getAuthors" v-model="question.answer2ExplainationId" />
                </b-field>
              </div>
              <div class="bold-text">
                הערה לתשובה
              </div>
              <ckeditor :editor="editor" v-model="question.answer2Remark" :config="editorConfig"></ckeditor>
            </div>

            <div class="item">
              <div style="display: flex; align-items: center;" class="bold-text">
                תשובה 3
                <b-checkbox class="bold-text" v-model="question.answer3IsCorrectAnswer">האם נכונה</b-checkbox>
              </div>
              <ckeditor :editor="editor" v-model="question.answer3Answer" :config="editorConfig"></ckeditor>
              <div style="display: flex; gap: 20px;">
                <b-field style="width: 30%;" label="מיומנות:">
                  <FieldInlineSelect :hideHover="true" style="border: 1px solid gray; width: 100%;" :filterable="true"
                    :clearable="true" :options="[{ Value: 0, Text: 'לא פעיל' }, { Value: 1, Text: 'פעיל' }]"
                    v-model="question.answer3Skill" />
                </b-field>
                <b-field style="width: 30%;" label="חומרת שגיאה:">
                  <FieldInlineSelect :hideHover="true" style="border: 1px solid gray; width: 100%;" :filterable="true"
                    :clearable="true" :optionsAsync="getAcademicInstitudes" v-model="question.answer2Severity" />
                </b-field>
                <b-field style="width: 60%;" label="הסבר:">
                  <FieldInlineSelect :hideHover="true" style="border: 1px solid gray; width: 100%;" :filterable="true"
                    :clearable="true" :optionsAsync="getAuthors" v-model="question.answer3ExplainationId" />
                </b-field>
              </div>
              <div class="bold-text">
                הערה לתשובה
              </div>
              <ckeditor :editor="editor" v-model="question.answer3Remark" :config="editorConfig"></ckeditor>
            </div>

            <div class="item">
              <div style="display: flex; align-items: center;" class="bold-text">
                תשובה 4
                <b-checkbox class="bold-text" v-model="question.answer3IsCorrectAnswer">האם נכונה</b-checkbox>
              </div>
              <ckeditor :editor="editor" v-model="question.answer4Answer" :config="editorConfig"></ckeditor>
              <div style="display: flex; gap: 20px;">
                <b-field style="width: 30%;" label="מיומנות:">
                  <FieldInlineSelect :hideHover="true" style="border: 1px solid gray; width: 100%;" :filterable="true"
                    :clearable="true" :options="[{ Value: 0, Text: 'לא פעיל' }, { Value: 1, Text: 'פעיל' }]"
                    v-model="question.answer4Skill" />
                </b-field>
                <b-field style="width: 30%;" label="חומרת שגיאה:">
                  <FieldInlineSelect :hideHover="true" style="border: 1px solid gray; width: 100%;" :filterable="true"
                    :clearable="true" :optionsAsync="getAcademicInstitudes" v-model="question.answer4Severity" />
                </b-field>
                <b-field style="width: 60%;" label="הסבר:">
                  <FieldInlineSelect :hideHover="true" style="border: 1px solid gray; width: 100%;" :filterable="true"
                    :clearable="true" :optionsAsync="getAuthors" v-model="question.answer4ExplainationId" />
                </b-field>
              </div>
              <div class="bold-text">
                הערה לתשובה
              </div>
              <ckeditor :editor="editor" v-model="question.answer4Remark" :config="editorConfig"></ckeditor>
            </div> -->

            <div class="item">
              <b-field label="הערות עורך:">
                <textarea type="text" v-model="question.editorNotes" style="border: 1px solid gray; width: 30%;"
                  rows="4" cols="50" name="" id=""></textarea>
              </b-field>
            </div>

            <!-- <div class="item">
              <div class="bold-text">
                (5 אחרונים) שאלה זו מופיעה בתרגולים
              </div>
              <div v-if="question.firstAppearance" class="question-instance">
                <div class="bold-text">מופע ראשון:</div>
                <a href="">{{ question.firstAppearance.exerciseName }}</a>
                <div>מתוך: {{ question.firstAppearance.lessonName }} בקורס: {{ question.firstAppearance.courseName }}
                </div>
              </div>
              <div class="question-instances">
                <div v-for="instance in question.lastFiveAppearances" :key="instance.id" class="question-instance">
                  <a href="">{{ instance.exerciseName }}</a>
                  <div>מתוך: {{ instance.lessonName }} בקורס: {{ instance.courseName }}</div>
                </div>
              </div>
            </div> -->

            <div class="question-save-buttons">
              <b-button type="is-info" class="new flex-2" @click="addQuestion">שמירת שינויים</b-button>
              <!-- <b-button type="is-info" class="new flex-2" @click="newQuestion">הוספת שאלה חדשה לבנק השאלות</b-button> -->
            </div>

          </div>
        </div>
      </div>
      <!-- <div v-if="tab === 2">
        <template>
          <div class="Activity__Component__TabsContent__Tasks__Header">
            <div class="new bold-text" @click="newVideo">רמת קושי ממוצעת בתרגול: {{ question.status || 6.5 }}</div>
          </div>
          <div class="section-container">
            <div style="display: flex; gap: 10px; margin-bottom: 10px;">
              <div class="bold-text">שאלות בתרגול</div>
              <b-button style="height: 25px;">הסתר חלופות</b-button>
              <b-button style="height: 25px;">הצג חלופות</b-button>
            </div>
            <small>מספר שאלות: {{ question.questions || 40 }} (*לא ניתן לסדר שאלות לתרגול פעיל)</small>
            <div class="document-table">
              <div class="columns">
                <div class="column">
                  סדר
                </div>
                <div class="column">
                  ID שאלה
                </div>
                <div class="column">
                  שאלה
                </div>
                <div class="column">
                  הערות עורך
                </div>
                <div class="column">
                  רמת קושי
                </div>
                <div class="column">

                </div>
                <div class="column">

                </div>
                <div class="column">

                </div>
              </div>
              <div class="row" v-for="(question, idx) in questionItems" :key="idx">
                <div class="column-item">{{ question.sortOrder }}</div>
                <div class="column-item">{{ question.id }}</div>
                <div class="column-item">{{ question.name }}</div>
                <div class="column-item">{{ question.editor }}</div>
                <div class="column-item">{{ question.difficulty }}</div>
                <div>
                  <b-tooltip type="is-white" label="הגדרות">
                    <a style="color: green;" class="updateItem">
                      <b-icon icon="file"> </b-icon>
                    </a>
                  </b-tooltip>
                </div>
                <div>
                  <b-tooltip type="is-white" label="הגדרות">
                    <a style="color: green;" class="updateItem">
                      <b-icon icon="file"> </b-icon>
                    </a>
                  </b-tooltip>
                </div>
                <div>
                  <b-tooltip type="is-white" label="הגדרות">
                    <a style="color: green;" class="updateItem">
                      <b-icon icon="file"> </b-icon>
                    </a>
                  </b-tooltip>
                </div>
              </div>
            </div>
          </div>
          <div class="item">
            <b-field label="בחר שאלה">
              <FieldInlineSelect style="width: 30%; background-color: #eee;" :closeOnSelect="false"
                :alert-not-exist="true" :hide-hover="true" label="" :multiple="true" :clearable="true" :filterable="true"
                placeholder="חיפוש חופשי" :options="[]" :fetch-options="onSearchNewQuestions" @onChange="onChange"
                :reduce="(item) => item" v-model="newQuestionIds" />
            </b-field>
          </div>
        </template>
      </div> -->
    </div>
  </div>
</template>

<script>
// import ActivityItem from "@/components/ActivityItem.vue";
import moment from "moment";
import FieldInlineSelect from '@/components/Fields/FieldInlineSelect.vue';
import FieldInlineDate from '@/components/Fields/FieldInlineDate.vue';
// import FieldInlineTextArea from '@/components/Fields/FieldInlineTextArea.vue';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
// import { ContentLoader } from "vue-content-loader";
import EntityItemsService from "@/services/EntityItemsService";
import vSelect from "vue-select";
import {
  // ModalProgrammatic as Modal,
  ToastProgrammatic as Toast
} from "buefy";
import "vue-select/src/scss/vue-select.scss";
import CoursesService from "@/services/CoursesService";
// import UserService from "@/services/UserService";
import _ from "lodash";
// import { ModalProgrammatic as Modal } from "buefy";
// import DialogAddVideo from "./DialogAddVideo.vue";
// import DialogAddExercise from "./DialogAddExercise.vue";
import AdminService from "../services/AdminService";

export default {
  name: "Activity",
  components: {
    // ActivityItem,
    // ContentLoader,
    vSelect,
    FieldInlineSelect,
    FieldInlineDate,
    // FieldInlineTextArea,
  },
  props: {
    activities: Array,
    loading: Boolean,
    entityType: String,
    entityId: String,
  },
  // updated() {
  //   const params = window.location.search.substring(1);
  //   const lastIdx = params.lastIndexOf("=");
  //   const taskId = params.slice(lastIdx + 1);
  //   const task = this.$refs[`taskElement${taskId}`][0];
  //   task.isOpen = true;
  //   this.$nextTick(() => {
  //     task.$el.scrollIntoView({ behavior: "smooth" });
  //   });
  // },
  computed: {
    course() {
      return this.$store.state.auth?.course;
    },
    inputVal() {
      return new Date(this.question.examDate);
    },
    getEffectTypes() {
      return [
        { Value: 0, Text: 'שאלה רגילה' },
        { Value: 1, Text: 'Timer' },
        { Value: 2, Text: 'PopUp' },
        { Value: 3, Text: 'HideAnswers' },
        { Value: 4, Text: 'InterferenceQuestion' },
        { Value: 5, Text: 'PopUpQuestionText' },
      ];
    },
    getSeverities() {
      return [
        { Value: 1, Text: '1' },
        { Value: 2, Text: '2' },
        { Value: 3, Text: '3' },
        { Value: 4, Text: '4' },
        { Value: 5, Text: '5' },
      ];
    },
    getAnswerSeverity() {
      return [
        { Value: 1, Text: '1' },
        { Value: 2, Text: '2' },
        { Value: 3, Text: '3' },
        { Value: 4, Text: '4' },
        { Value: 5, Text: '5' },
      ];
    },
    lesson() {
      return this.$store.state.lesson;
    },
    userName() {
      return `${this.$store.state.user.privateName} ${this.$store.state.user.lastName}`;
    },
    haveNoActivities() {
      return (
        !this.loading &&
        (!this.groupedActivities || !Object.keys(this.groupedActivities).length)
      );
    },
    haveNoNotes() {
      return (
        !this.loading &&
        (!this.groupedActivitiesNotes ||
          !Object.keys(this.groupedActivitiesNotes).length)
      );
    },
    haveNoTasks() {
      return (
        !this.loading &&
        (!this.groupedActivitiesTasks ||
          !Object.keys(this.groupedActivitiesTasks).length)
      );
    },
    groupedActivities() {
      if (this.activities && this.activities.length) {
        const withoutDeleted = this.activities.filter(
          (item) => this.deletedItems.indexOf(item.Id) === -1
        );
        return _.groupBy(withoutDeleted, (item) =>
          moment(item.CreateDate, "YYYY-MM-DDThh:mm").format("MM/YYYY")
        );
      }
      return null;
    },
    groupedActivitiesNotes() {
      if (this.activities && this.activities.length) {
        const withoutDeleted = this.activities.filter(
          (item) =>
            this.deletedItems.indexOf(item.Id) === -1 && item.ItemType === 1
        );
        return _.groupBy(withoutDeleted, (item) =>
          moment(item.CreateDate, "YYYY-MM-DDThh:mm").format("MM/YYYY")
        );
      }
      return null;
    },
    groupedActivitiesTasks() {
      if (this.activities && this.activities.length) {
        const withoutDeleted = this.activities.filter(
          (item) =>
            this.deletedItems.indexOf(item.Id) === -1 && item.ItemType === 2
        );
        return _.groupBy(withoutDeleted, (item) =>
          moment(item.CreateDate, "YYYY-MM-DDThh:mm").format("MM/YYYY")
        );
      }
      return null;
    },
  },
  data() {
    return {
      tab: 1,
      showNewNote: false,
      showNewTask: false,
      deletedItems: [],
      categories: [],
      isLoading: false,
      question: {
        status: true,
        correctClauses: [],
        answers: [
          {
            isCorrectAnswer: false,
            answer: '',
            skill: null,
            severity: null,
            explanationId: null,
            remark: null
          },
          {
            isCorrectAnswer: false,
            answer: '',
            skill: null,
            severity: null,
            explanationId: null,
            remark: null
          },
          {
            isCorrectAnswer: false,
            answer: '',
            skill: null,
            severity: null,
            explanationId: null,
            remark: null
          },
          {
            isCorrectAnswer: false,
            answer: '',
            skill: null,
            severity: null,
            explanationId: null,
            remark: null
          },
        ]
      },
      questionItems: null,
      laws: null,
      verdicts: null,
      newQuestionIds: null,
      editor: ClassicEditor,
      editorConfig: {
        // The configuration of the editor.
      }
    };
  },
  created() {
    // this.loadQuestion();
    this.getCategories();
  },
  methods: {
    onDelete(id) {
      this.deletedItems.push(id);
    },
    back() {
      this.$router.replace({ path: `/admin/questionsbank` });
    },
    saveTask(data) {
      this.$refs.taskActivity.setLoading(true);
      EntityItemsService.addTask(this.entityType, this.entityId, data)
        .then((r) => {
          this.activities.unshift(r.data);
          this.showNewTask = false;
        })
        .catch(() => {
          this.$refs.taskActivity.setLoading(false);
        })
        .finally(() => { });
    },
    getExplanations() {
      return () =>
        CoursesService.getExplanations().then((r) =>
          r.data.map((i) => ({ Value: i.id, Text: i.answerExplenationData }))
        );
    },
    getMeyumanot() {
      return () =>
        CoursesService.getMeyumanot().then((r) =>
          r.data.map((i) => ({ Value: i.id, Text: i.skillname }))
        );
    },
    onSearchNewQuestions({ search, loading, setData, setValue }) {
      if (search.length > 3) {
        loading(true);
        return AdminService.getQuestionsInBank({
          Terms: search,
          PageSize: 999,
          IsAscending: false,
          PageNumber: 1,
          ResultType: 3
        })
          .then((r) => {
            if (!r.data.items.length) {
              setValue({ Value: -1, Text: search });
            } else {
              setData(r.data.items.map((i) => ({ Value: i.id, Text: `${i.question} - ${i.id}` })));
            }
          })
          .finally(() => {
            loading(false);
          });
      }

      return null;
    },
    getCategories() {
      CoursesService.getCategories().then((r) =>
        this.categories = r.data.map((i) => ({ Value: i.id, Text: i.name }))
      );
    },
    getAcademicInstitudes() {
      return () =>
        AdminService.getAcademicInstitudes().then((r) =>
          r.data.map((i) => ({ Value: i.id, Text: i.name }))
        );
    },
    getAuthors() {
      return () =>
        AdminService.getAuthors().then((r) =>
          r.data.map((i) => ({ Value: i.id, Text: i.name }))
        );
    },
    saveNote(data) {
      this.$refs.noteActivity.setLoading(true);
      EntityItemsService.addNote(this.entityType, this.entityId, {
        Body: data.Body,
      })
        .then((r) => {
          this.activities.unshift(r.data);
          this.showNewNote = false;
        })
        .finally(() => {
          this.$refs.noteActivity.setLoading(false);
        });
    },
    close() {
      this.showNewNote = false;
    },
    closeTask() {
      this.showNewTask = false;
    },
    newNote() {
      this.showNewNote = true;
    },
    newTask() {
      this.showNewTask = true;
    },
    newVideo() {
      // Modal.open({
      //   component: DialogAddVideo,
      //   customClass: "entity-modal",
      //   props: {

      //   },
      //   canCancel: false,
      //   trapFocus: true,
      // });
    },
    newQuestion() {
      // Modal.open({
      //   component: DialogAddExercise,
      //   customClass: "entity-modal",
      //   props: {

      //   },
      //   canCancel: false,
      //   trapFocus: true,
      // });
    },
    getExerciseSubjects() {
      return () =>
        CoursesService.getExerciseSubjects().then((r) =>
          r.data.map((i) => ({ Value: i.id, Text: i.subject }))
        );
    },
    getLaws() {
      return () =>
        CoursesService.getLaws().then((r) =>
          r.data.map((i) => ({ Value: i.id, Text: i.name }))
        );
    },
    getLawClauses() {
      return () =>
        CoursesService.getLawClauses(this.question.law.id).then((r) =>
          r.data.map((i) => ({ Value: i.id, Text: i.name })),
        );
    },
    addLawClause() {
      this.question.correctClauses.push({
        lawId: this.question.law.Value,
        lawName: this.question.law.Text,
        clauseId: this.question.clauseId
      });
      this.question.lawId = null;
      this.question.clauseId = null;
    },
    removeLawClause(clauseId) {
      console.log(clauseId);
      const idx = this.question.correctClauses.findIndex((c) => c.clauseId === clauseId);
      this.question.correctClauses.splice(idx, 1);
    },
    searchLawClause() {
      this.getLawClauses();
    },
    addQuestion() {
      this.isLoading = true;
      AdminService.createQuestion({
        ...this.question
      }).then((r) => {
        this.question = r.data;
        Toast.open({
          type: "is-success",
          message: "העדכון בוצע בהצלחה!",
          duration: 8000,
        });
        this.$router.push('/admin/questionsbank');
      }).catch(() => {
        Toast.open({
          type: "is-danger",
          message: "השיוך נכשל!",
          duration: 8000,
        });
      }).finally(() => {
        this.isLoading = false;
      });
    },
    onChange() { },
    loadQuestion() {
      this.isLoading = true;
      AdminService.getQuestionDetails(this.entityId, {
        LessonIds: [this.entityId]
      }).then((r) => {
        this.question = r.data.items;
      })
        .finally(() => {
          this.tab = 1;
          this.isLoading = false;
        });
    },
    loadQuestionItems() {
      this.isLoading = true;
      AdminService.getAdminQuestions(this.$store.state.auth.course?.id, {
        LessonIds: [this.entityId]
      })
        .then((r) => {
          this.questionItems = r.data.items;
        })
        .finally(() => {
          this.tab = 2;
          this.isLoading = false;
        });
    },
    loadLaws() {
      this.isLoading = true;
      AdminService.getAdminLaws(this.$store.state.auth.course?.id, {
        LessonIds: [this.entityId]
      })
        .then((r) => {
          this.laws = r.data.items;
        })
        .finally(() => {
          this.tab = 3;
          this.isLoading = false;
        });
    },
    loadVerdicts() {
      this.isLoading = true;
      AdminService.getAdminVerdicts(this.$store.state.auth.course?.id, {
        LessonIds: [this.entityId]
      })
        .then((r) => {
          this.verdicts = r.data.items;
        })
        .finally(() => {
          this.tab = 4;
          this.isLoading = false;
        });
    },
    parsedGroupedDate(date) {
      if (date) {
        const localLocale = moment(date, "MM/YYYY");
        localLocale.locale("he");
        return localLocale.format("MMMM YYYY");
      }
      return null;
    },
    parseDate(date) {
      return moment(date).format("DD/MM/YYYY HH:mm");
    },
  },
};
</script>

<style scoped lang="scss">
.Activity__Component {
  background-color: #fff;
}

.Entity__Details__PageOptions__Back {
  border-radius: 3px;
  padding: 10px 25px;
  color: #0091ae;
  position: relative;
  right: -5px;
  font-size: 14px;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }

  i {
    width: 11px;
    display: inline-block;
    vertical-align: middle;

    img {
      transform: rotateZ(-90deg);
    }
  }
}

.question-details {
  display: flex;
  margin-top: 20px;
  // justify-content: space-between;
}

.question-detail {
  display: flex;
  align-items: center;
  gap: 5px;

  p {
    width: 350px;
  }
}

.question-instance {
  display: flex;
  align-items: center;
  gap: 10px;
}

.question-buttons {
  display: flex;
  justify-content: space-between;
}

.item {
  border-bottom: 1px solid #eee;
  margin-bottom: 10px;
  padding: 20px 10px;
  background-color: white;
}

.bold-text {
  font-weight: bold;
}

.Activity__Component__Tabs {
  display: flex;
  border-bottom: 1px solid #cbd6e2;
  margin: 0px 20px;
  position: sticky;
  top: 0px;
  z-index: 2;
  background-color: #fff;
  padding-top: 6px;

  .Activity__Component__Tabs__Item {
    padding: 15px 25px;
    position: relative;
    cursor: pointer;
    font-size: 15px;

    &:not(.active):hover {
      color: #4188b7;
    }

    &.active:after {
      content: "";
      width: 100%;
      position: absolute;
      z-index: 2;
      bottom: -3px;
      background-color: #2e3f50;
      height: 5px;
      right: 0;
      border-radius: 3px;
    }
  }
}

.section-container {
  display: flex;
  flex-direction: column;
  margin: 0 2% 50px 3%;

  // overflow-x: hidden;
  // align-items: center;
  &:last-child {
    // margin: 0 5% 0 5%;
  }

  img {
    height: 15px;
    margin: 0 2px;
  }

  h1 {
    // margin: 0 2%;
    // width: 15%;
    color: #1eb2d0;
    font-size: 17px;
    font-weight: 700;
    text-align: center;
  }

  p {
    padding: 0px 1%;
    width: 83%;
    // overflow: hidden;
    // text-overflow: ellipsis;
    word-break: break-word;

    ::v-deep .title {
      display: none;
    }
  }

  .workplan {
    display: flex;
    flex-direction: column;

    span {
      // display: flex;
      // align-items: center;
      width: fit-content;
    }

  }
}

.document-table-title-container {
  display: flex;
  justify-content: space-between;
  align-content: center;
  align-items: center;

  // margin-bottom: 25px;
  .document-table-title {
    color: #1eb2d0;
    font-size: 17px;
    font-weight: 700;
  }

  .label-upload-file {
    margin-left: 20px;
    font-weight: 500;
    background-color: transparent;
    border: none;
    color: #57b7ca;
    cursor: pointer;
  }
}

.document-table {
  display: table;
  border-collapse: collapse;
  width: 100%;
  margin-bottom: 70px;
  border: 1px solid #d2d2d2;
  background-color: #fff;
  padding: 5px;
  margin-top: 15px;

  .columns {
    font-weight: bold;
    background-color: #E98F12;
    text-align: center;
  }

  .columns,
  .row {
    display: table-row;
    border-bottom: 1px solid #d2d2d2;
    margin: 0;

    .removeItem,
    .updateItem {
      text-decoration: underline;
    }

    &>div {
      display: table-cell;
      padding: 10px 5px;
      font-size: 14px;
      vertical-align: middle;
    }

    .Table_Field {
      min-width: 170px;
    }
  }
}

.Activity__Component__TabsContent {
  padding: 20px 30px;
  background-color: #eeeeee;

  & .Activity__Item {
    margin: 8px 0;
  }
}

.loading {
  max-height: 300px;
  width: 600px;
  margin: 0 auto;
}

.no-activities {
  font-size: 25px;
  text-align: center;
  opacity: 0.8;

  span {
    margin-top: 50px;

    ::v-deep .mdi-timeline-text-outline {
      &::before {
        font-size: 50px;
      }
    }
  }
}

button.new {
  background-color: #3b5671;
  color: white;
  border: none;
  border-radius: 3px;
  padding: 8px 18px;
  // cursor: pointer;

  &:active {
    transform: scale(0.98);
  }

  &:hover {
    background-color: #4d6782;
  }
}

.question-save-buttons {
  display: flex;
  justify-content: space-around;
  width: 30%;
  gap: 20px;
  margin-top: 15px;
}

.flex-1 {
  flex: 1;
}

.flex-2 {
  flex: 2;
}

.Activity__Component__TabsContent__Tasks__Header {
  display: flex;
  justify-content: flex-end;
}

.Activity__Component__TabsContent__Tasks__Content {
  h1 {
    text-align: center;
  }
}
</style>
